import React, { useState, useEffect } from 'react';
import axios from "axios";

const useFetch = (initialQuery) => {
    const [data, setData] = useState();
    const [query, setQuery] = useState(initialQuery);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
   
    useEffect(() => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);
   
        try {
          const result = await axios(query);
   
          setData(result.data);
        } catch (error) {
          setIsError(true);
        }
   
        setIsLoading(false);
      };
   
      fetchData();
    }, [query]);
   
    return [{ data, isLoading, isError }, setQuery];
}

export default useFetch;