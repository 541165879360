import React from "react"

import SEO from "../components/seo"

import { ThemeProvider } from "styled-components"
import { theme } from "../styles"

import { GlobalStyle } from "../styles"
import Layout from "src/components/Layout"

import useFetch from "src/hooks/useFetch"

const ProjectsPage = props =>{

 const   [{ data, isLoading, isError }, setQuery]=    useFetch({  method: 'get',
    url: 'https://api.github.com/users/feux07/repos'})



return (  <ThemeProvider theme={theme}>
    <Layout theme={theme} withBreadCrumb activePage="Projects" textAlign={"left"}>
      <GlobalStyle bodyMarginTop="0vw" />
      <SEO title="Projects" />


{
    isLoading ? 
    (<div>Fetching projects from git server...</div>):
    (<ul>
        {data.map(item => (
          <li key={item.id}>
            <a href={item.html_url}>{item.name}</a>
          </li>
        ))}
      </ul>)
}
      
    </Layout>
  </ThemeProvider>
)
}
export default ProjectsPage
